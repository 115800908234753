import React, { useState } from 'react'
import QuickBookContext from "./QuickBookContext"

const QuickBookProvider = props => {
	const [payLoader, setPayLoader] = useState(false)
	const [feeSkeleton] = useState({
		"active": false,
		"charge_percentage": null,
		"charge_price": 0,
		"charge_value": 0,
		"description": "Bait Fee",
		"title": "Bait Fee",
		"type": "BAITS_FEE"
	})
	const [updatedFee, setUpdatedFee] = useState([])
	const [newfee, setNewFee] = useState([])
	const [BaitsFeeDesc, setBaitsFeeDesc] = useState('')
	const [ServiceFeeDesc, setServiceFeeDesc] = useState('')
	const [MatesFeeDesc, setMatesFeeDesc] = useState('')
	const [ExtraFeeDesc, setExtraFeeDesc] = useState({
		"extra_fee_status": false,
		"extra_fee_percentage": null,
		"extra_fee_applied_amount": 0,
		"extra_fee_value": 0,
		"extra_fee_description": "",
	})
	const [tip, setTip] = useState({
		"tipAmt": 0,
		"tiptype": "Cash Tip Upon Trip",
		"custom": false,
		"tipPer": null
	})
	const [promo, setPromo] = useState({
		"show": false,
		"promoCode": "",
		"error": "",
		"applied": false,
		"response": null,
		"custom": false
	})
	const [deposit, setDeposit] = useState({
		"depositPer": 15,
		"depositValue": 0,
		"custom": false
	})
	const [custom_Addon, setCustom_Addon] = useState(false)
	const [customer, setCustomer] = useState({
		"type": null,
		"firstname": null,
		"lastname": null,
		"email": null,
		"phone": null,
		"customer_country_iso_code": "US",
		"customer_country_phone_code": '+1',
		"lastBooking": null
	})
	const [payment, setPayment] = useState({
		"type": null,
		"stripeLoaded": false,
		"cardNumberError": "",
		"cardExpiryError": "",
		"cardCVCError": "",
		"activated": false,
		"zipcode": ""
	})
	const [optionalAddon, setOptionalAddon] = useState({
		"addOnCheck": {},
		"addonAmt": 0.00
	})
	const [selectedGV, setSelectedGV] = useState({
		"guide": null,
		"guideid": null,
		"vehicle": null,
		"vehicleid": null,
		"guidetitle": null
	})
	const [error, setError] = useState({
		"isError": false,
		"errorTitle": null,
		"errorMsg": null
	})
	const [guestlist, setGuestList] = useState([])
	const [minimumDeposit, setMinimumDeposit] = useState(null)
	const [custom_CustomerAddOns, setCustom_CustomerAddOns] = useState([])
	const [consumerAddOn, setConsumerAddOn] = useState([])
	const [cloneddata, setClonedData] = useState({
		"MatesFeeDesc": "",
		"BaitsFeeDesc": "",
		"ServiceFeeDesc": "",
		"ExtraFeeDesc": ExtraFeeDesc,
		"custom_Addon": false,
		"tip": tip,
		"promo": promo,
		"deposit": deposit,
		"addOnCheck": {},
		"addonAmt": 0
	})
	const [invalidDesc, setInvalidDesc] = useState(false)

	return (
		<QuickBookContext.Provider
			value={{
				payLoader, setPayLoader,
				feeSkeleton,
				updatedFee, setUpdatedFee,
				newfee, setNewFee,
				BaitsFeeDesc, setBaitsFeeDesc,
				ServiceFeeDesc, setServiceFeeDesc,
				MatesFeeDesc, setMatesFeeDesc,
				custom_Addon, setCustom_Addon,
				ExtraFeeDesc, setExtraFeeDesc,
				custom_CustomerAddOns, setCustom_CustomerAddOns,
				tip, setTip,
				promo, setPromo,
				deposit, setDeposit,
				customer, setCustomer,
				payment, setPayment,
				optionalAddon, setOptionalAddon,
				selectedGV, setSelectedGV,
				error, setError,
				guestlist, setGuestList,
				minimumDeposit, setMinimumDeposit,
				consumerAddOn, setConsumerAddOn,
				cloneddata, setClonedData,
				invalidDesc, setInvalidDesc
			}}
		>
			{props.children}
		</QuickBookContext.Provider>
	);
};
export default QuickBookProvider

